<template>
  <div class="relate_enterprise">
    <div class="row">
      <div class="txt_row">
        <span>共</span>
        <span class="color1">{{ total }}</span>
        <span>条数据，本月更新</span>
        <span class="color1">{{ updatedDataMonth ? updatedDataMonth : 0 }}</span>
        <span>条，累计更新</span>
        <span class="color1">{{ cumulativeUpdateData ? cumulativeUpdateData : 0 }}</span>
        <span>条</span>
      </div>
    </div>
    <div class="card_list">
      <div class="card_item" v-for="item in list" :key="item.id">
        <div class="card_left">
          <div class="img_box">
            <img v-if="item.companyLogo" :src="item.companyLogo" alt="" />
            <img v-else src="@/assets/image/enterprise-default-logo.png" alt="" />
          </div>
          <div class="info_box">
            <div class="row">
              <span>{{ item.companyFullName }}</span>
            </div>
            <div class="row">
              <div v-if="item.companyLabelList && item.companyLabelList.length">
                <div class="tag_list" v-if="item.companyLabelList.length > 4">
                  <div v-for="(i1, i2) in item.tagList" :key="i2">
                    <div class="tag_item" v-if="i2 < 4">
                      {{ i1 }}
                    </div>
                  </div>
                  <div
                    class="show_more"
                    @mouseenter="item.isShowTag = true"
                    @mouseleave="item.isShowTag = false"
                  >
                    {{ item.companyLabelList.length + '...' }}
                    <ul
                      class="show_tag_box"
                      @mouseenter="item.isShowTag = true"
                      @mouseleave="item.isShowTag = false"
                      v-show="item.isShowTag"
                    >
                      <li v-for="(i1, i2) in item.tagList" :key="i2">
                        <div class="tag">
                          {{ i1 }}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="tag_list" v-else>
                  <div v-for="(i1, i2) in item.companyLabelList" :key="i2">
                    <div class="tag_item">
                      {{ i1 }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <span>{{ item.legalPerson }}</span>
              <span>{{ item.companyPhone }}</span>
              <span>{{ item.city }}<span v-if="item.district ">-{{ item.district }}</span></span>
              <span class="website" @click="toOfficialWebsite(item.officialWebsite)">{{
                item.officialWebsite
              }}</span>
            </div>
          </div>
        </div>
        <div class="card_right">
          <div class="box_list">
            <!-- @click="toEnterpriseDetails(item.id, 1)" -->
            <div class="item" @click="toEnterpriseDetails(item, 1)">
              <div class="num">{{ item.contactPerson ? item.contactPerson : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <!-- <i class="iconfont icon-jr-icon-zhanshi"></i> -->
                </div>
                <span >联络人</span>
              </div>
            </div>
            <div class="item"  @click="toEnterpriseDetails(item, 2)">
              <div class="num">{{ item.caseCount ? item.caseCount : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <!-- <i class="iconfont icon-jr-icon-six-copy"></i> -->
                </div>
                <span>场景信息</span>
              </div>
            </div>
            <div class="item" @click="toEnterpriseDetails(item, 4)">
              <div class="num">{{ item.serviceCount ? item.serviceCount : 0 }}</div>
            
              <div class="row">
                <div class="icon_box">
                  <!-- <i class="iconfont icon-jr-icon-user1"></i> -->
                </div>
                <span>服务记录</span>
              </div>
            </div>
           
            <div class="item" @click="toEnterpriseDetails(item, 6)" >
              <div class="num">{{ item.planNum ? item.planNum : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <!-- <i class="iconfont icon-jr-icon-five"></i> -->
                </div>
                <span>案例数据</span>
              </div>
            </div>
          
            
          
            <div class="item" @click="toEnterpriseDetails(item, 3)">
              <div class="num">{{ item.diagnosisNum ? item.diagnosisNum : 0 }}</div>
              <div class="row">
                <div class="icon_box">
                  <!-- <i class="iconfont icon-jr-icon-document"></i> -->
                </div>
                <span>潜在线索</span>
              </div>
            </div>
          </div>
        </div>
        <div class="update_time">
          <i class="iconfont icon-jr-icon-refresh1 color1"></i>
          <span>最近服务更新时间：</span>
          <span>{{ item.lastUpdateTime }}</span>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
      <div class="go_back">
        <el-button @click="goback" type="primary"  >返回上一级</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { listCompanyPage, listCustomerCount } from '@/api/customerManager.js'
// const operatorId = sessionStorage.getItem('operatorId')
const defaultQueryInfo = Object.freeze({
  // operatorId,
  pageNum: 1,
  pageSize: 10,
  customerId: null //  客户经理编号
})

export default {
  name: 'relateEnterprise',
  components: {},
  props: {
    id: {
      type: Number
    }
  },
  data() {
    return {
      queryInfo: { ...defaultQueryInfo }, //查询传参
      list: [], // 企业列表
      cumulativeUpdateData: 0, // 累计更新数据
      updatedDataMonth: 0, // 本月更新
      total: 0 //总条数
    }
  },
  watch: {
    id: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.queryInfo.customerId = newVal
        } else if (oldVal) {
          this.queryInfo.customerId = newVal
        }
      },
      immediate: true
    }
  },
  created() {
    this.search()
  },
  methods: {
    async search() {
      const res = await listCompanyPage(this.queryInfo)
      if (res.resultCode === 200) {
        res.data.list.forEach((el) => {
          if (el.companyLabelList && el.companyLabelList.length) {
            if (el.companyLabelList.length > 4) {
              el.isShowTag = false
              el.tagList = el.companyLabelList.filter((i1, i2) => i2 < 4)
            }
          }
        })
        this.list = res.data.list
        this.total = res.data.total
        // this.$message.success('获取客户经理信息成功!')
        const result = await listCustomerCount({ customerId: this.id })
        if (result.resultCode === 200) {
          this.cumulativeUpdateData = result.data.cumulativeUpdateData
          this.updatedDataMonth = result.data.updatedDataMonth
        }
      } else {
        this.$message.warning('获取客户经理信息失败!')
      }
    },
     //跳转
     toEnterpriseDetails(item, val) {
      this.$router.push({
        name: 'EnterpriseDetails',
        // path: '/enterprisedetails',
        query: {
          id: item.id,
          val,
          customerId: item.customerId,
          orgCompanyId: item.orgCompanyId,
          companyFullName: item.companyFullName
        }
      })
    },
    // 返回企业类型标签绑定的class
    getEnterpriseScale(val) {
      if (val === '大型') {
        return 'bc_color1'
      } else if (val === '中型') {
        return 'bc_color2'
      } else {
        return 'bc_color3'
      }
    },
    //监听pagesize变化
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize
      this.search()
    },
    //监听页码变化 配置智参中心
    handleCurrentChange(newCurrent) {
      this.queryInfo.pageNum = newCurrent
      this.search()
    },
      // 返回
      goback() {
      this.$router.go(-1)
    },

    // 跳转官网
    toOfficialWebsite(val) {
      window.open(val)
    }
  }
}
</script>

<style lang="less" scoped>
.relate_enterprise {
  width: 100%;
}
.new_page {
  margin: 10px auto;
  width: 100%;
  text-align: center;
  position: relative;
  .go_back{
   margin-top: 10px;
  }
}
.card_list {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: calc(100vh - 420px);
  overflow-y: auto;
  padding: 20px;
  box-sizing: border-box;
  /* 滚动条整体部分 */
  &::-webkit-scrollbar {
    width: 3px;
    height: 9px;
  }
  /* 滚动条里面的滑块 */
  &::-webkit-scrollbar-thumb {
    background-color: rgba(144, 147, 153, 0.3);
    border-radius: 20px;
    transition: background-color 0.3s;
  }
  .card_item {
    display: flex;
    min-height: 120px;
    margin-bottom: 10px;
    padding: 10px;
    box-sizing: border-box;
    border: #e5e5e5 1px solid;
    border-radius: 8px;
    position: relative;
    .update_time {
      position: absolute;
      top: 10px;
      right: 20px;
      span {
        font-size: 12px;
        color: #448aff;
      }
    }
    .card_left {
      display: flex;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      .img_box {
        width: 74px;
        height: 74px;
        margin-right: 10px;
        img {
          width: 74px;
        height: 74px;
        }
      }
      .info_box {
        display: flex;
        justify-content: center;
        flex-flow: column;
        .enterprise_name {
          font-weight: bold;
          font-size: 18px;
          line-height: 24px;
          color: #000;
        }
        .type_tag {
          font-size: 10px;
          color: #fff;
          text-align: center;
          margin-left: 10px;
          padding: 5px;
          border-radius: 2px;
        }
        .tag_list {
          display: flex;
          .tag_item {
            font-size: 10px;
            color: #4e93fb;
            border: #559eff 1px solid;
            border-radius: 4px;
            padding: 5px;
            margin-right: 10px;
          }
          .show_more {
            position: relative;
            font-size: 14px;
            line-height: 26px;
            text-align: center;
            cursor: pointer;
            .show_tag_box {
              position: absolute;
              top: 100%;
              left: 100%;
              z-index: 99;
              width: 240px;
              padding: 6px;
              box-sizing: border-box;
              border-radius: 4px;
              background-color: #fff;
              border: #f8f8f8 1px solid;
              display: flex;
              flex-flow: row wrap;
              li {
                padding: 3px 5px;
                .tag {
                  width: fit-content;
                  font-size: 10px;
                  line-height: 20px;
                  color: #4e93fb;
                  border: #559eff 1px solid;
                  border-radius: 4px;
                  padding: 0 6px;
                  box-sizing: border-box;
                }
              }
            }
          }
        }
        .row span {
          font-size: 14px;
          margin-right: 10px;
        }
        .website {
          cursor: pointer;
          font-size: 14px;
          color: #448aff;
        }
        .website:hover {
          text-decoration: underline;
        }
      }
    }
    .card_right {
      display: flex;
      margin-left: auto;
      .box_list {
        display: flex;
        align-items: center;
        margin-top: auto;
        .item {
          display: flex;
          flex-flow: column;
          justify-content: center;
          text-align: center;
          margin-right: 30px;
          cursor: pointer;
          .num {
            font-weight: bold;
            font-size: 24px;
            color: #151515;
            margin-bottom: 10px;
          }
          .txt {
            font-size: 16px;
            color: #151515;
          }
        }
        .item:hover {
          .num {
            color: #448aff;
          }
          .icon_box {
            color: #448aff;
          }
          span {
            color: #448aff;
          }
        }
      }
      .btn_box {
        display: flex;
        flex-flow: column;
        margin-left: 50px;
        .row span {
          font-size: 12px;
        }
        .btn_list {
          ::v-deep .el-button {
            background: #fee1cc;
            border: none;
            color: #fc8b39;
          }
          .el-button,
          span:hover {
            opacity: 0.7;
          }
          span {
            font-size: 14px;
          }
        }
      }
    }
  }
}
.row {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  .txt_row {
    font-size: 16px;
  }
}
.color1 {
  color: #4e93fb;
}
.bc_color1 {
  background-color: #4e93fb;
}
.bc_color2 {
  background-color: #44d112;
}
.bc_color3 {
  background-color: #08cac9;
}
</style>
